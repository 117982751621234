import React, { useState } from 'react';
import PopUp from './pop-up';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
} from 'react-share';

interface ShareMenuProps {
    country?: string;
    imageUrl?: string;
}

const ButtonMobile: React.FC<ShareMenuProps> = ({ country, imageUrl }) => {
    const [isOpen, setIsOpen] = useState(false);
    const shareUrl = 'https://t.me/miss_charm_bot';
    const title = `Vote to support Miss Charm ${country} in entering the Top 5 of the Miss Charm 2024`;
    const image = `${imageUrl}`;
    const desFb = `Support Miss Charm ${country} to reach the Top 5! Vote here: https://t.me/miss_charm_bot`;
    const openPopUp = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    return (
        <div className='flex w-full gap-2 mt-4 px-1'>
            <a href='https://t.me/miss_charm_bot' target='blank' className='vote-button w-100 flex-1 py-2 flex items-center justify-center text-center text-[#FFFFFF] secondary-font font-medium text-sm cursor-pointer hover:opacity-90 transition-all max-w-full'>
                Vote
            </a>
            <div onClick={openPopUp} className='font-medium text-sm share-button w-100 flex-1 py-2 flex items-center justify-center text-center text-[#FFFFFF] secondary-font cursor-pointer hover:opacity-90 transition-all max-w-full'>
                Share
            </div>
            <PopUp isOpen={isOpen} onRequestClose={handleClose}>
                <div className="flex space-x-3">
                    <FacebookShareButton resource={imageUrl} url={image} hashtag={desFb}>
                        <FacebookIcon size={40} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl} title={title}>
                        <TwitterIcon size={40} round />
                    </TwitterShareButton>
                    {/* <LinkedinShareButton url={shareUrl} title={title} source={imageUrl}>
                        <LinkedinIcon size={40} round />
                    </LinkedinShareButton> */}
                    <TelegramShareButton url={shareUrl} title={title}>
                        <TelegramIcon size={40} round />
                    </TelegramShareButton>
                    {/* <WhatsappShareButton url={shareUrl} title={title}>
                        <WhatsappIcon size={40} round />
                    </WhatsappShareButton> */}
                </div>
            </PopUp>
        </div>
    );
};

export default ButtonMobile;
