import styled from 'styled-components';
import appBg from '@/assets/bg.png';
import missBg from '@/assets/background/all-miss-bg.png';
import button from '@/assets/share-button.png';

export const Wrapper = styled.div`
  background: url(${appBg}), url(${missBg});
  min-height: 100vh;

  .leaderboard-list {
    padding: 24px 32px 16px 32px;
    gap: 0px;
    border-radius: 20px;
    opacity: 0px;
    background: var(--Grey-Subtle, #222933);
    border: 1px solid #ffffff33;
  }
  .table-divider {
    border-top: 1px solid #ffffff33;
  }
  .button {
    background: url(${button});
    background-size: 100% 100%;
    box-shadow: 0px 0px 120px 0px #52617c;
    border-radius: 666px;
    overflow: hidden;
    radial-gradient(56.66% 53.75% at 74.44% 16.25%, rgba(218, 234, 241, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }
`;
