import http from './axios-client';

// export async function getMissLeaderboard(top: number, offset: number) {
//   const response = await http.get(`/api/v1/landing-data/squads/rank?offset=${offset}&limit=${top}`);
//   if (response.status === 200) {
//     return response.data;
//   } else {
//     return null;
//   }
// }
export async function getMissLeaderboard(top: number, offset: number) {
  const response = await http.get(`/api/v1/public/squad/all?offset=${offset}&limit=${top}`);
  if (response.status === 200) {
    const data = response?.data?.data?.sort((a:any,b:any)=>parseInt(b.total_points)-parseInt(a.total_points)).slice(0,10)
    return {data};
  } else {
    return null;
  }
}

export async function getSquadLeaderboard(squadId: string | undefined) {
  const response = await http.get(`/api/v1/landing-data/user/ranks?limit=10&offset=0&squadId=${squadId}`);
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
}

export async function getAllSquad() {
  const response = await http.get('/api/v1/public/squad/all');
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
}

export async function getSquadById(squadId: string | undefined) {
  const response = await http.get(`/api/v1/public/squad/${squadId}`);
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
}
