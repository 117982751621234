import React from 'react';
import Modal from 'react-modal';

interface PopUpProps {
    isOpen: boolean;
    onRequestClose: () => void;
    children?: React.ReactNode;
}

const PopUp: React.FC<PopUpProps> = ({ isOpen, onRequestClose, children }) => {
    // Modal.setAppElement('#root');
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Pop Up Modal"
            className="max-w-3xl mx-auto rounded-lg shadow-lg p-6 w-full bg-[#333]"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
            <div className='flex items-center text-end'>
                <span className='flex-1 flex justify-end' onClick={onRequestClose}>
                    <button
                        className=""
                    >
                        <i className="fa fa-times text-xl" aria-hidden="true"></i>
                    </button>
                </span>
            </div>
            <h2 className="flex-1 text-xl font-bold text-center">Share on Social Media</h2>
            <div className='flex justify-center mt-5 mb-5'>
                {children}
            </div>
        </Modal>
    );
};

export default PopUp;
