import React, { memo } from 'react';
import MissCharm from '@/assets/footer/icon.svg';
import Vote3Logo from '@/assets/footer/logo-vote3.png';
import Twitter from '@/assets/footer/twitter.svg';
import LinkedIn from '@/assets/footer/linkedin.svg';
import Discord from '@/assets/footer/discord.svg';
import Telegram from '@/assets/footer/telegram.svg';
import { Wrapper } from './footer.styled';

const FooterComponent = () => {
  return (
    <Wrapper className='flex flex-col items-center relative py-10'>
      <div className='flex items-center space-x-3'>
      <img src={Vote3Logo} alt="" className='h-10 w-10' />
      <img src={MissCharm} alt=''  />
      </div>
      <div className='flex gap-12 my-6 items-center'>
        <a href="https://www.facebook.com/MISSCHARM.TV" target='blank'>
          <i className="fab fa-facebook-square text-2xl"></i>
        </a>
        <a href="https://www.instagram.com/misscharm.tv/" target='blank'>
          <i className="fab fa-instagram text-2xl"></i>
        </a>
        <a href="https://www.tiktok.com/@misscharmofficial" target='blank'>
          <i className="fab fa-tiktok text-2xl"></i>
        </a>
        <a href="https://t.me/misscharminternational" target='blank'>
          <i className="fab fa-telegram text-2xl"></i>
        </a>
        <a href="https://x.com/MisscharmTV" target='blank'>
          <i className="fab fa-twitter text-2xl"></i>
        </a>
      </div>
      <div className='footer-text text-[#FFFFFF80]'>© 2024 MissBlockCharm International. All rights reserved.</div>
    </Wrapper>
  );
};

export const Footer = memo(FooterComponent);
