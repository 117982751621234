import styled from 'styled-components';
import appBg from '@/assets/bg.png';
import missBg from '@/assets/background/all-miss-bg.png';
import voteButton from '@/assets/vote-button.png';
import shareButton from '@/assets/share-button.png';

export const Wrapper = styled.div`
  background: 
    url(${appBg}) no-repeat center top, 
    url(${missBg}) no-repeat center top;
  min-height: 100vh;
  background-size: 'cover',
  background-repeat: 'no-repeat'

  .miss-info {
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    opacity: 0px;
    angle: -180 deg;
    background: var(--Ink-100, #222933);
    box-shadow: 0px 0px 30px 0px #1E1B1B inset;
  }
  .vote-button {
    background: url(${voteButton});
    background-size: 100% 100%;
    box-shadow: 0px 0px 120px 0px #52617c;
    border-radius: 666px;
    overflow: hidden;
    radial-gradient(56.66% 53.75% at 74.44% 16.25%, rgba(218, 234, 241, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }
  .share-button {
    background: url(${shareButton});
    background-size: 100% 100%;
    box-shadow: 0px 0px 120px 0px #52617c;
    border-radius: 666px;
    overflow: hidden;
    radial-gradient(56.66% 53.75% at 74.44% 16.25%, rgba(218, 234, 241, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }
  .miss-info {
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    opacity: 0px;
    angle: -180 deg;
    background: var(--Ink-100, #222933);
    box-shadow: 0px 0px 30px 0px #1E1B1B inset;
  }
`;
