import styled from 'styled-components';
export const Wrapper = styled.div`
  background: var(--Grey-Subtle, #222933);
  width: 100vw;

  .footer-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;
