import React, { memo, useEffect, useState } from 'react';
import LeftArrow from '@/assets/left-arrow.svg';
import { Wrapper } from './misspage.styled';
import QRCode from '@/assets/qr-code.svg';
import MissLayout from '@/assets/leaderboard/layout.png';
import Miss from '@/assets/leaderboard/miss.png';
import { Footer } from '@/components/footer/footer';
import { useNavigate } from 'react-router';
import { getAllSquad } from '@/utilities/miss-data';
import ShareMenu from './shera-button';
import ButtonMobile from './button-mobile';
const cards = [
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  },
  {
    QRCode: QRCode,
    missAvatar: Miss,
    missLayout: MissLayout,
    missName: 'Russia',
    missXp: 250000
  }
];

const MissPage = () => {
  const navigate = useNavigate();
  const [squadSet, setSquadSet] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchAllSquad() {
      const response = await getAllSquad();
      if (response) {
        setSquadSet(response.data);
      }
    }
    fetchAllSquad();
  }, []);
  return (
    <Wrapper>
      <div className='desktop-display'>
        <div className='w-100 flex justify-center'>
          <div className='block' style={{ maxWidth: "1280px" }}>
            <div className='flex pt-24 pl-4 pb-16' data-aos="fade-right">
              <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/')} />
              <div className='ml-8 secondary-font text-[96px] font-normal'>Miss Charm 2024</div>
            </div>
            <div className='flex flex-wrap' data-aos="fade-up">
              {squadSet &&
                squadSet.map &&
                squadSet.map(
                  (
                    miss: {
                      id: string | undefined;
                      avatar: string | undefined;
                      QRCode: string | undefined;
                      miss: string | undefined;
                      country: string | undefined;
                    },
                    index: number | 0
                  ) => (
                    <div key={index} className='w-1/4 px-4'>
                      <div className='w-full mb-6'>
                        <div className='relative cursor-pointer'>
                          {/* onClick={() => navigate(`/miss-detail/${miss.id}`)} */}
                          <img src={miss.avatar} className='block w-full h-auto' alt='' /> {/* Add w-full */}
                          <img src={miss.QRCode} className='absolute bottom-0 right-0' alt='' />
                        </div>
                        <div className='miss-info flex flex-col items-center py-4'>
                          <div className='secondary-font font-normal text-xl'>{miss.miss}</div>
                          <div className='secondary-font font-normal text-3xl'>{miss.country}</div>
                          <ShareMenu country={miss.country} imageUrl={miss.avatar} />
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className='mobile-display'>
        <div className='flex mx-4 py-10'>
          <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/')} />
          <div className='ml-8 primary-font text-xl font-semibold'>Miss Charm</div>
        </div>
        <div className='flex flex-wrap mx-2'>
          {squadSet &&
            squadSet.map &&
            squadSet.map(
              (
                miss: {
                  id: string | undefined;
                  avatar: string | undefined;
                  QRCode: string | undefined;
                  miss: string | undefined;
                  country: string | undefined;
                },
                index: number | 0
              ) => (
                <div key={index} className='w-1/2 px-2'>
                  <div className=' w-full mb-6'>
                    <div className='relative cursor-pointer'>
                      <img src={miss.avatar} className='block w-full h-auto' alt='' /> {/* Add w-full */}
                      <img src={miss.QRCode} className='absolute bottom-0 right-0' alt='' />
                    </div>
                    <div className='miss-info flex flex-col items-center py-4'>
                      <div className='secondary-font font-normal text-lg text-center' style={{lineHeight: '1.3rem'}}>{miss.miss}</div>
                      <div className='secondary-font font-normal text-center'>{miss.country}</div>
                      {/* <div className='flex w-full gap-2 px-2 mt-4'>
                        <a href='https://t.me/miss_charm_bot' target='blank' className='vote-button w-[120px] h-11 flex items-center justify-center text-center text-[#FFFFFF] secondary-font font-medium text-sm cursor-pointer hover:opacity-90 transition-all max-w-full'>
                          Vote
                        </a>
                      </div> */}
                        <ButtonMobile country={miss.country} imageUrl={miss.avatar} />
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
      <div className='mt-60'>
        <Footer />
      </div>
    </Wrapper>
  );
};

export const AllMissPage = memo(MissPage);
