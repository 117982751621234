/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash/isEmpty';

interface IENVS {
  VITE_BASE_API: string;
  VITE_BASE_CHAIN_ID: string[];
  VITE_BASE_GAMETOKEN: string;
  VITE_BASE_DINAMOND: string;
  VITE_BASE_NFTCONTRACT: string;
  VITE_BASE_LPCONTRACT: string;
  VITE_BASE_POSTHOG_KEY: string;
  VITE_BASE_POSTHOG_HOST: string;
  VITE_BASE_ENABLE_CHAT: boolean;
  VITE_BASE_AI_CHAT_URL: string;
  VITE_BASE_ENVIRONMENT: string;
  VITE_SERVER_API: string;
}

const defaultEnvs: IENVS = {
  VITE_BASE_API: '',
  VITE_BASE_CHAIN_ID: [],
  VITE_BASE_GAMETOKEN: '',
  VITE_BASE_DINAMOND: '',
  VITE_BASE_NFTCONTRACT: '',
  VITE_BASE_LPCONTRACT: '',
  VITE_BASE_POSTHOG_KEY: '',
  VITE_BASE_POSTHOG_HOST: '',
  VITE_BASE_ENABLE_CHAT: false,
  VITE_BASE_AI_CHAT_URL: '',
  VITE_BASE_ENVIRONMENT: 'development',
  VITE_SERVER_API: ''
};

export const getEnvs = () => {
  let envs: any = {};
  try {
    const ENVS = import.meta.env;
    if (!isEmpty(ENVS)) {
      Object.keys(ENVS).forEach((key: string) => {
        const value = ENVS[key];
        if (value?.length) {
          envs[key] = ENVS[key]?.split(',');
        } else {
          if (value === 'true' || value === 'false') {
            envs[key] = value === 'true';
          } else {
            envs[key] = ENVS[key];
          }
        }
        return key;
      });
    }
  } catch (error) {
    console.debug(error);
  } finally {
    envs = isEmpty(envs) ? defaultEnvs : envs;
  }
  return { ...envs };
};

export const ENVS: IENVS = getEnvs();
