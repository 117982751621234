import { ENVS } from '@/config/env.config';
import axios from 'axios';

const TIMEOUT = 20000;
const http = axios.create({
  timeout: TIMEOUT
});
const HEADERS = { 'Content-Type': 'application/json' };

http.interceptors.request.use(
  (req: any) => {
    req.baseURL = `${ENVS.VITE_SERVER_API}/`;
    req.headers = {
      ...HEADERS
    };
    return req;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default http;
