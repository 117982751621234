import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Wrapper } from './miss-detail.styled';
import LeftArrow from '@/assets/left-arrow.svg';
import Miss1 from '@/assets/miss/miss-img-1.png';
import Miss2 from '@/assets/miss/miss-img-2.png';
import Miss3 from '@/assets/miss/miss-img-3.png';
import Miss4 from '@/assets/miss/miss-img-4.png';
import Miss5 from '@/assets/miss/miss-img-5.png';
import DescCard from '@/assets/miss/miss-desc-card.png';
import MissAvatar from '@/assets/leaderboard/miss-small-avatar.png';
import QRCode from '@/assets/miss/qr-code.png';
import { Footer } from '@/components/footer/footer';
import { getSquadById, getSquadLeaderboard } from '@/utilities/miss-data';

const dataSource = [
  {
    id: '1',
    name: 'John Doe',
    xp: 1200
  },
  {
    id: '2',
    name: 'Jane Smith',
    xp: 1500
  },
  {
    id: '3',
    name: 'Alice Johnson',
    xp: 800
  },
  {
    id: '4',
    name: 'Bob Brown',
    xp: 500
  },
  {
    id: '5',
    name: 'Alice Johnson',
    xp: 800
  },
  {
    id: '6',
    name: 'Alice Johnson',
    xp: 800
  },
  {
    id: '7',
    name: 'Alice Johnson',
    xp: 800
  },
  {
    id: '8',
    name: 'Alice Johnson',
    xp: 800
  },
  {
    id: '9',
    name: 'Alice Johnson',
    xp: 800
  },
  {
    id: '10',
    name: 'Alice Johnson',
    xp: 800
  }
];
const imageList = [
  {
    image: Miss1
  },
  {
    image: Miss2
  },
  {
    image: Miss3
  },
  {
    image: Miss4
  },
  {
    image: Miss5
  }
];
const MissDetailComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [squad, setSquad] = useState<any>({});
  const [leaderBoard, setLeaderBoard] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      // eslint-disable-next-line no-inner-declarations
      async function fetchSquadDetailData() {
        const squadResponse = await getSquadById(id);
        const leaderboardResponse = await getSquadLeaderboard(id);
        if (squadResponse) {
          setSquad(squadResponse.data);
          setLeaderBoard(leaderboardResponse.data.items);
        }
      }
      fetchSquadDetailData();
    }
  }, []);
  console.log(squad);
  console.log(leaderBoard);
  return (
    squad && (
      <Wrapper className='flex flex-col'>
        <div className='desktop-display'>
          <div className='px-36'>
            <div className='flex pt-40 pb-20'>
              <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/')} />
              <div className='ml-8 secondary-font text-[40px] font-normal'>
                Miss Charm {squad.country} - {squad.miss}
              </div>
            </div>
            <div className='flex'>
              {imageList.length > 0 && <img src={imageList[0].image} alt='' className='w-1/2 h-full' />}
              <div className='flex flex-wrap ml-1 w-1/2'>
                {imageList &&
                  imageList.map((image, index) => {
                    if (index > 0) {
                      return (
                        <div
                          className={`w-1/2 px-1 ${index === 1 || index === 2 ? 'mb-1' : index === 3 || index === 4 ? 'mt-1' : ''}`}
                          key={index}
                        >
                          <img src={image.image} className='h-full' />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <div className='flex justify-between mt-32'>
              <div className='w-[70%]'>
                {squad.information && squad.information.length > 0 && (
                  <div className='mb-32 flex'>
                    <div
                      className='py-10 bg-center px-8'
                      style={{
                        backgroundImage: `url(${DescCard})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                      }}
                    >
                      <div className='flex items-center'>
                        <img src={squad.avatar} className='w-9 h-9' alt='' />
                        <div className='ml-4'>
                          <div className='tertiary-font font-normal text-2xl text-white'>{squad.miss}</div>
                          <div className='primary-font font-normal text-sm text-[#FFFFFF80]'>
                            Miss Charm {squad.country}
                          </div>
                        </div>
                      </div>
                      <div className='primary-font font-normal text-base text-[#FFFFFF80] mt-4'>{squad.information}</div>
                    </div>
                  </div>
                )}

                <div className='leaderboard-list'>
                  <div className='primary-font font-semibold text-xl'>Leaderboard</div>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th className='p-4 text-left w-1/12'>#</th>
                        <th className='p-4 text-left'>Name</th>
                        <th className='p-4 text-right'>XP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaderBoard &&
                        leaderBoard.map &&
                        leaderBoard.map((item: any, index: number) => (
                          <tr key={index} className='table-divider'>
                            <td className='p-4'>{index + 1}</td>
                            <td className='p-4'>{item.username}</td>
                            <td className='p-4 text-right'>{item.point.toLocaleString()} XP</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <img src={QRCode} alt='' />
                <div className='flex justify-between mt-8'>
                  <div className='button w-[120px] h-11 flex items-center justify-center text-center text-[#FFFFFF] secondary-font font-medium text-sm cursor-pointer hover:opacity-90 transition-all max-w-full'>
                    Vote
                  </div>
                  <div className='button w-[120px] h-11 flex items-center justify-center text-center text-[#FFFFFF] secondary-font font-medium text-sm cursor-pointer hover:opacity-90 transition-all max-w-full'>
                    Share
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='my-24'></div>
        </div>
        <div className='mobile-display'>
          <div className='flex mx-4 py-10'>
            <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/')} />
            <div className='ml-8 primary-font text-xl font-semibold'>
              Miss Charm {squad.country} - {squad.miss}
            </div>
          </div>
          <div className='flex flex-col'>
            {imageList.length > 0 && <img src={imageList[0].image} alt='' className='m-4 rounded-2xl' />}
            <div className='flex flex-wrap px-3 w-full'>
              {imageList &&
                imageList.map((image, index) => {
                  if (index > 0) {
                    return (
                      <div className='w-1/2 p-1' key={index}>
                        <img src={image.image} className='rounded-2xl' />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div
            className='py-14 bg-center px-8 mt-10 mx-4'
            style={{
              backgroundImage: `url(${DescCard})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            <div className='flex items-center'>
              <img src={squad.avatar} className='w-9 h-9' alt='' />
              <div className='ml-4'>
                <div className='tertiary-font font-normal text-2xl text-white'>{squad.miss}</div>
                <div className='primary-font font-normal text-sm text-[#FFFFFF80]'>Miss Charm {squad.country}</div>
              </div>
            </div>
            <div className='primary-font font-normal text-base text-[#FFFFFF80] mt-4'>{squad.information}</div>
          </div>
          <div className='leaderboard-list mx-4 mt-10 mb-20'>
            <div className='primary-font font-semibold text-xl'>Leaderboard</div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th className='p-4 text-left w-1/12'>#</th>
                  <th className='p-4 text-left'>Name</th>
                  <th className='p-4 text-right'>XP</th>
                </tr>
              </thead>
              <tbody>
                {leaderBoard &&
                  leaderBoard.map &&
                  leaderBoard.map((item: any, index: number) => (
                    <tr key={index} className='table-divider'>
                      <td className='p-4'>{index + 1}</td>
                      <td className='p-4'>{item.username}</td>
                      <td className='text-right'>{item.point.toLocaleString()} XP</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <Footer />
      </Wrapper>
    )
  );
};

export const MissDetail = memo(MissDetailComponent);
